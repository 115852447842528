import React, { useState, useEffect, useMemo } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUp, faUpDown } from '@fortawesome/free-solid-svg-icons';
import { useTable, useSortBy } from 'react-table';
import './clients.css'; // Importing the client.css file
import Layout from '../../components/layout/Layout';
import { faSyncAlt } from '@fortawesome/free-solid-svg-icons';

const Clients = () => {
  const [clients, setClients] = useState([]);
  const [filteredClients, setFilteredClients] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [page, setPage] = useState(1);
  const limit = 10;
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedRows, setSelectedRows] = useState([]);
  const [newClientsCount, setNewClientsCount] = useState(0); // To display newly added clients count

  // Fetch clients from the API
  const fetchClients = async () => {
    setLoading(true);
    setError(null);

    const token = localStorage.getItem('token');

    if (!token) {
      setError("Token is missing. Please login again.");
      setLoading(false);
      return;
    }

    const myHeaders = new Headers();
    myHeaders.append('Authorization', `Bearer ${token}`);

    const requestOptions = {
      method: 'GET',
      headers: myHeaders,
    };

    try {
      const response = await fetch('https://ace.dotcombusiness.in/api/admin/view_user', requestOptions);
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const result = await response.json();
      setClients(result.data);
      setFilteredClients(result.data);
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchClients();
  }, []);

  useEffect(() => {
    if (searchQuery) {
      const filteredData = clients.filter(client => {
        return (
          client.name?.toLowerCase().includes(searchQuery.toLowerCase()) ||
          client.email?.toLowerCase().includes(searchQuery.toLowerCase()) ||
          client.mobile?.includes(searchQuery) ||
          client.city?.toLowerCase().includes(searchQuery.toLowerCase()) ||
          client.state?.toLowerCase().includes(searchQuery.toLowerCase()) ||
          client.role?.toLowerCase().includes(searchQuery.toLowerCase())
        );
      });
      setFilteredClients(filteredData);
      setPage(1);
    } else {
      setFilteredClients(clients);
    }
  }, [searchQuery, clients]);

  const totalPages = Math.ceil(filteredClients.length / limit);

  const paginatedClients = useMemo(() => {
    const start = (page - 1) * limit;
    const end = start + limit;
    return filteredClients.slice(start, end);
  }, [filteredClients, page, limit]);

  // Function to verify the client with confirmation alert
const verifyClient = async (userId) => {
  const confirmed = window.confirm('Are you sure you want to verify this client?');
  if (!confirmed) {
    // If the user presses "No", exit the function
    return;
  }

  const token = localStorage.getItem('token');
  
  if (!token) {
    setError("Token is missing. Please login again.");
    return;
  }

  const myHeaders = new Headers();
  myHeaders.append('Authorization', `Bearer ${token}`);
  myHeaders.append('Content-Type', 'application/json');

  const requestOptions = {
    method: 'PATCH',
    headers: myHeaders,
  };

  try {
    const response = await fetch(`https://ace.dotcombusiness.in/api/admin/make_verify/${userId}`, requestOptions);
    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }

    await response.json();
    fetchClients(); // Refresh the clients list after verification
  } catch (error) {
    setError(error.message);
  }
};

// Update the "Verified" column's rendering logic
const columns = useMemo(
  () => [
    { Header: 'Client Name', accessor: 'name', sortable: true },
    { Header: 'Email', accessor: 'email', Cell: ({ value }) => (value ? value : 'N/A') },
    { Header: 'Mobile', accessor: 'mobile' },
    { Header: 'Role', accessor: 'role' },
    { Header: 'City', accessor: 'city' },
    { Header: 'State', accessor: 'state', Cell: ({ value }) => (value ? value : 'N/A') },
    { 
      Header: 'Verified', 
      accessor: 'verified', 
      Cell: ({ row }) => (
        row.original.verified === '1' ? (
          <button disabled className="verify-button verified">Verified</button>
        ) : (
          <button onClick={() => verifyClient(row.original.id)} className="verify-button">Accept </button>
        )
      ),
    },
  ],
  []
);


  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable(
    { columns, data: paginatedClients, initialState: { sortBy: [] } },
    useSortBy
  );

  const handlePageChange = (newPage) => {
    if (newPage > 0 && newPage <= totalPages) setPage(newPage);
  };

  const handleRowSelection = (id, isChecked) => {
    setSelectedRows((prevSelected) =>
      isChecked ? [...prevSelected, id] : prevSelected.filter((selectedID) => selectedID !== id)
    );
  };

  // Sync clients function
  const syncClients = async () => {
    const token = localStorage.getItem('token'); // Use token from localStorage

    if (!token) {
      setError("Token is missing. Please login again.");
      return;
    }

    const myHeaders = new Headers();
    myHeaders.append('Authorization', `Bearer ${token}`); // Use token from localStorage
  
    const requestOptions = {
      method: 'GET',
      headers: myHeaders,
    };
  
    try {
      const response = await fetch('{{$base_url}}/api/admin/import_user/', requestOptions);
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
  
      const result = await response.json();
      const newClients = result.new_data || []; // Assume the API returns `new_data` for new clients
  
      setClients((prevClients) => [...prevClients, ...newClients]); // Append new clients to the existing list
      setFilteredClients((prevClients) => [...prevClients, ...newClients]); // Update filtered clients too
      setNewClientsCount(newClients.length); // Set the count of newly added clients
    } catch (error) {
      setError(error.message); // Handle error if request fails
    }
  };

  const handleSelectAllRows = (isChecked) => {
    const allIDs = paginatedClients.map((client) => client.id);
    setSelectedRows(isChecked ? allIDs : []);
  };

  const startEntry = (page - 1) * limit + 1;
  const endEntry = Math.min(page * limit, filteredClients.length);

  const getPageNumbers = () => {
    const startPage = Math.max(1, page - 1);
    const endPage = Math.min(totalPages, page + 1);
    const pages = [];
    for (let i = startPage; i <= endPage; i++) pages.push(i);
    return pages;
  };

  return (
    <Layout>
      <div className="search-sync-container">
        <div className="sync-container">
          <button onClick={syncClients} className="sync-button">
            <FontAwesomeIcon icon={faSyncAlt} className="sync-icon" />
            Sync
          </button>
          {newClientsCount > 0 && (
            <span className="new-products-info" style={{ marginLeft: '15px', color: '#28a745', fontWeight: 'bold' }}>
              {newClientsCount} new clients added
            </span>
          )}
        </div>
        <div className="search-container">
          <input
            type="text"
            placeholder="Search across all fields..."
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            className="search-input"
          />
        </div>
      </div>

      {loading && <p>Loading...</p>}
      {error && <p className="error">{error}</p>}

      {!loading && !error && (
        <>
          <div className="table-container">
            <table {...getTableProps()} className="clients-table">
              <thead>
                {headerGroups.map((headerGroup) => (
                  <tr {...headerGroup.getHeaderGroupProps()} className="header-row" style={{ background: 'linear-gradient(120deg, #9aafb8, #2280e5e0)', borderBottom: '3px solid lightgrey' }}>
                    <th>
                      <input type="checkbox" onChange={(e) => handleSelectAllRows(e.target.checked)} />
                    </th>
                    {headerGroup.headers.map((column) => (
                      <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                        <span style={{ display: 'flex', justifyContent: 'space-between' }}>
                          {column.render('Header')}
                          <span className="sort-icon">
                            {column.isSorted ? (
                              column.isSortedDesc ? <FontAwesomeIcon icon={faArrowUp} flip="vertical" /> : <FontAwesomeIcon icon={faArrowUp} />
                            ) : (
                              <FontAwesomeIcon icon={faUpDown} />
                            )}
                          </span>
                        </span>
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody {...getTableBodyProps()}>
                {rows.map((row) => {
                  prepareRow(row);
                  return (
                    <tr {...row.getRowProps()} className="body-row">
                      <td>
                        <input type="checkbox" checked={selectedRows.includes(row.original.id)} onChange={(e) => handleRowSelection(row.original.id, e.target.checked)} />
                      </td>
                      {row.cells.map((cell) => (
                        <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                      ))}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>

          <div className="v">
            <div className="entries-info">
              Showing {startEntry} to {endEntry} of {filteredClients.length} entries
            </div>

            <div className="pagination-controls">
              <button onClick={() => handlePageChange(page - 1)} disabled={page === 1} className="pagination-button">
                Previous
              </button>

              {getPageNumbers().map((pageNum) => (
                <button key={pageNum} onClick={() => handlePageChange(pageNum)} className={`pagination-button ${page === pageNum ? 'active' : ''}`}>
                  {pageNum}
                </button>
              ))}

              <button onClick={() => handlePageChange(page + 1)} disabled={page === totalPages} className="pagination-button">
                Next
              </button>
            </div>
          </div>
        </>
      )}
    </Layout>
  );
};

export default Clients;
