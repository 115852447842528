import React, { useState } from 'react';
import './sideBar.css';
import { FaTachometerAlt, FaBox, FaUsers, FaCartPlus, FaBars, FaTimes } from 'react-icons/fa';
import { Link } from 'react-router-dom';

const SideBar = ({ onCollapseChange }) => {
    const [isCollapsed, setIsCollapsed] = useState(true);

    const toggleSidebar = () => {
        const newCollapseState = !isCollapsed;
        setIsCollapsed(newCollapseState);
        onCollapseChange(newCollapseState); // Notify parent of the new state
    };

    return (
        <div className={`sidebar ${isCollapsed ? 'collapsed' : ''}`}>
            <button className="toggle-button" onClick={toggleSidebar}>
                {isCollapsed ? <FaBars /> : <FaTimes />}
            </button>
            <ul className="menu">
                <li className="menu-item">
                    <Link to='/dashboard' className='menu-link'>
                        <FaTachometerAlt className="menu-icon" />
                        <span className="menu-text">Dashboard</span>
                    </Link>
                </li>
                <li className="menu-item">
                    <Link to='/products' className='menu-link'>
                        <FaBox className="menu-icon" />
                        <span className="menu-text">Products</span>
                    </Link>
                </li>
                <li className="menu-item">
                    <Link to='/orders' className='menu-link'>
                        <FaCartPlus className="menu-icon" />
                        <span className="menu-text">Orders</span>
                    </Link>
                </li>
                <li className="menu-item">
                    <Link to='/clients' className='menu-link'>
                        <FaUsers className="menu-icon" />
                        <span className="menu-text">Clients</span>
                    </Link>
                </li>
            </ul>
        </div>
    );
};

export default SideBar;
