import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import { Link } from 'react-router-dom';
import './dashboard.css';
import Layout from '../../components/layout/Layout'; // Adjust the path as necessary

const Dashboard = () => {
    const location = useLocation();
    const [dashboardData, setDashboardData] = useState({
        total_users: 0,
        total_products: 0,
        total_orders: 0
    });

    const token = localStorage.getItem('token');

    const myHeaders = new Headers();
    myHeaders.append('Authorization', `Bearer ${token}`);

    const requestOptions = {
      method: 'GET',
      headers: myHeaders,
    };

    // Fetch dashboard data from API
    const fetchDashboardData = async () => {
        try {
            const response = await fetch('https://ace.dotcombusiness.in/api/admin/dashboard', requestOptions);
            const result = await response.json();
            if (response.ok && result.data.length > 0) {
                setDashboardData(result.data[0]);
            } else {
                toast.error('Failed to fetch dashboard data');
            }
        } catch (error) {
            toast.error('An error occurred while fetching the dashboard data');
        }
    };

    useEffect(() => {
        fetchDashboardData();
    }, []);

    useEffect(() => {
        if (location.state?.loginMessage) {
            toast.success(location.state.loginMessage);
            // Clear state to prevent showing the toast again on refresh
            window.history.replaceState({}, document.title);
        }
    }, [location.state]);

    return (
        <Layout>
            <div className="dashboard-container" style={{ alignItems: 'flex-start', justifyContent: 'flex-start' }}>
                <div className="card-container">
                    <div className="card">
                        <Link to='/products' className="card-links">
                            <h1 className="card-title" >{dashboardData.total_products}</h1>
                            <h2 className="card-title">Products</h2>
                            <p className="card-content">Overview of your products.</p>
                        </Link>
                    </div>
                    <div className="card">
                        <Link to='/clients' className="card-links">
                            <h1 className="card-title">{dashboardData.total_users}</h1>
                            <h2 className="card-title">Clients</h2>
                            <p className="card-content">List of your clients.</p>
                        </Link>
                    </div>
                    <div className="card">
                        <Link to='/orders' className="card-links">
                            <h1 className="card-title">{dashboardData.total_orders}</h1>
                            <h2 className="card-title">Orders</h2>
                            <p className="card-content">Summary of recent orders.</p>
                        </Link>
                    </div>
                    
                </div>
            </div>
            <ToastContainer />
        </Layout>
    );
};

export default Dashboard;
