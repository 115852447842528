import React, { useState, useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom'; // Import useNavigate
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUp, faUpDown, faFilePdf } from '@fortawesome/free-solid-svg-icons';
import { useTable, useSortBy } from 'react-table';
import '../clients/clients.css'; // Importing the orders.css file
import Layout from '../../components/layout/Layout';

const Orders = () => {
  const [orders, setOrders] = useState([]);
  const [filteredOrders, setFilteredOrders] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [page, setPage] = useState(1);
  const limit = 10;
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedRows, setSelectedRows] = useState([]);

  const navigate = useNavigate(); // Initialize useNavigate

  const fetchOrders = async () => {
    setLoading(true);
    setError(null);

    const token = localStorage.getItem('token');

    if (!token) {
      setError("Token is missing. Please login again.");
      setLoading(false);
      return;
    }

    const myHeaders = new Headers();
    myHeaders.append('Authorization', `Bearer ${token}`);

    const requestOptions = {
      method: 'GET',
      headers: myHeaders,
    };

    try {
      const response = await fetch('https://ace.dotcombusiness.in/api/admin/view_order', requestOptions);
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const result = await response.json();
      setOrders(result.data);
      setFilteredOrders(result.data);
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchOrders();
  }, []);

  useEffect(() => {
    if (searchQuery) {
      const filteredData = orders.filter(order => {
        return (
          String(order.order_id || '').toLowerCase().includes(searchQuery.toLowerCase()) ||
          String(order.user.name || '').toLowerCase().includes(searchQuery.toLowerCase()) ||
          String(order.user.mobile || '').toLowerCase().includes(searchQuery.toLowerCase()) ||
          String(order.status || '').toLowerCase().includes(searchQuery.toLowerCase()) ||
          String(order.type || '').toLowerCase().includes(searchQuery.toLowerCase()) ||
          String(order.order_date || '').includes(searchQuery) ||
          String(order.amount || '').includes(searchQuery)
        );
      });
      setFilteredOrders(filteredData);
      setPage(1);
    } else {
      setFilteredOrders(orders);
    }
  }, [searchQuery, orders]);

  const totalPages = Math.ceil(filteredOrders.length / limit);

  const paginatedOrders = useMemo(() => {
    const start = (page - 1) * limit;
    const end = start + limit;
    return filteredOrders.slice(start, end);
  }, [filteredOrders, page, limit]);

  const columns = useMemo(
    () => [
      { Header: 'Order ID', accessor: 'order_id' },
      { Header: 'User Name', accessor: 'user.name' },
      { Header: 'Mobile', accessor: 'user.mobile' },
      { Header: 'Order Date', accessor: 'order_date' },
      { Header: 'Status', accessor: 'status' },
      { Header: 'Type', accessor: 'type' },
      { Header: 'Amount', accessor: 'amount' },
      {
        Header: 'PDF Invoice',
        accessor: 'order_invoice',
        Cell: ({ row }) => (
          row.original.order_invoice ? (
            <a href={row.original.order_invoice} target="_blank" rel="noopener noreferrer">
              <button className="pdf-button" style={{ color: '#e31e25', cursor: 'pointer', border: 'none', background: 'none' }}>
                <FontAwesomeIcon icon={faFilePdf} size="3x" />
              </button>
            </a>
          ) : (
            <button className="pdf-button" disabled style={{ color: '#c89293', cursor: 'not-allowed', border: 'none', background: 'none' }}>
              <FontAwesomeIcon icon={faFilePdf} size="2x" />
            </button>
          )
        ),
      },
    ],
    []
  );

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable(
    { columns, data: paginatedOrders, initialState: { sortBy: [] } },
    useSortBy
  );

  const handlePageChange = (newPage) => {
    if (newPage > 0 && newPage <= totalPages) setPage(newPage);
  };

  const handleRowSelection = (id, isChecked) => {
    setSelectedRows((prevSelected) =>
      isChecked ? [...prevSelected, id] : prevSelected.filter((selectedID) => selectedID !== id)
    );
  };

  const handleSelectAllRows = (isChecked) => {
    const allIDs = paginatedOrders.map((order) => order.id);
    setSelectedRows(isChecked ? allIDs : []);
  };

  const startEntry = (page - 1) * limit + 1;
  const endEntry = Math.min(page * limit, filteredOrders.length);

  const getPageNumbers = () => {
    const startPage = Math.max(1, page - 1);
    const endPage = Math.min(totalPages, page + 1);
    const pages = [];
    for (let i = startPage; i <= endPage; i++) pages.push(i);
    return pages;
  };

  // New function to handle the invoice generation and redirection to the new form page
  const handleGenerateInvoice = async (orderId) => {
    const token = localStorage.getItem('token');

    if (!token) {
      setError("Token is missing. Please login again.");
      return;
    }

    const myHeaders = new Headers();
    myHeaders.append('Authorization', `Bearer ${token}`);

    const requestOptions = {
      method: 'GET',
      headers: myHeaders,
    };

    try {
      const response = await fetch(`https://ace.dotcombusiness.in/api/admin/return_order/${orderId}`, requestOptions);
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const result = await response.json();

      // Redirect to the new form page and pass the API response data as state
      navigate(`./genarate_invoice/${orderId}`, { state: { invoiceData: result } });

    } catch (error) {
      setError(error.message);
    }
  };

  return (
    <Layout>
      <div className="search-container">
        <input
          type="text"
          placeholder="Search orders..."
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          className="search-input"
        />
      </div>

      {loading && <p>Loading...</p>}
      {error && <p className="error">{error}</p>}

      {!loading && !error && (
        <>
          <div className="table-container">
            <table {...getTableProps()} className="clients-table">
              <thead>
                {headerGroups.map((headerGroup) => (
                  <tr
                    {...headerGroup.getHeaderGroupProps()}
                    className="header-row"
                    style={{ background: 'linear-gradient(120deg, #9aafb8, #2280e5e0)', borderBottom: '3px solid lightgrey' }}
                  >
                    <th>
                      <input type="checkbox" onChange={(e) => handleSelectAllRows(e.target.checked)} />
                    </th>
                    {headerGroup.headers.map((column) => (
                      <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                        <span style={{ display: 'flex', justifyContent: 'space-around' }}>
                          {column.render('Header')}
                          <span className="sort-icon">
                            {column.isSorted ? (
                              column.isSortedDesc ? <FontAwesomeIcon icon={faArrowUp} flip="vertical" /> : <FontAwesomeIcon icon={faArrowUp} />
                            ) : (
                              <FontAwesomeIcon icon={faUpDown} />
                            )}
                          </span>
                        </span>
                      </th>
                    ))}
                    <th>Generate Invoice</th>
                  </tr>
                ))}
              </thead>
              <tbody {...getTableBodyProps()}>
                {filteredOrders.length === 0 ? (
                  <tr>
                    <td colSpan={headerGroups[0].headers.length + 1} style={{ textAlign: 'center' }}>
                      <p className="no-records-message">No records found</p>
                    </td>
                  </tr>
                ) : (
                  rows.map((row) => {
                    prepareRow(row);
                    return (
                      <tr {...row.getRowProps()} className="body-row">
                        <td>
                          <input
                            type="checkbox"
                            checked={selectedRows.includes(row.original.id)}
                            onChange={(e) => handleRowSelection(row.original.id, e.target.checked)}
                          />
                        </td>
                        {row.cells.map((cell) => (
                          <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                        ))}
                        <td style={{ justifyContent: 'center', display: 'flex'}}>
                          <button
                            className="pagination-button"
                            onClick={() => handleGenerateInvoice(row.original.id)}
                          >
                            Generate
                          </button>
                        </td>
                      </tr>
                    );
                  })
                )}
              </tbody>
            </table>
          </div>

          <div className="v">
            <div className="entries-info">
              Showing {startEntry} to {endEntry} of {filteredOrders.length} entries
            </div>

            <div className="pagination-controls">
              <button onClick={() => handlePageChange(page - 1)} disabled={page === 1} className="pagination-button">
                Previous
              </button>

              {getPageNumbers().map((pageNum) => (
                <button key={pageNum} onClick={() => handlePageChange(pageNum)} className={`pagination-button ${page === pageNum ? 'active' : ''}`}>
                  {pageNum}
                </button>
              ))}

              <button onClick={() => handlePageChange(page + 1)} disabled={page === totalPages} className="pagination-button">
                Next
              </button>
            </div>
          </div>
        </>
      )}
    </Layout>
  );
};

export default Orders;
