import React, { useState, useEffect, useMemo } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSyncAlt, faArrowUp, faUpDown } from '@fortawesome/free-solid-svg-icons';
import { useTable, useSortBy } from 'react-table';
import './products.css'; // Importing the general table CSS
import Layout from '../../components/layout/Layout';

const Products = () => {
  const [allProducts, setAllProducts] = useState([]); // Stores all products
  const [filteredProducts, setFilteredProducts] = useState([]); // Stores filtered products (search + filter)
  const [searchQuery, setSearchQuery] = useState('');
  const [page, setPage] = useState(1);
  const limit = 10; // Number of products per page
  const [totalPages, setTotalPages] = useState(1);
  const [newProductsCount, setNewProductsCount] = useState(0); // For displaying newly added products count
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedRows, setSelectedRows] = useState([]);
  const [imageFilter, setImageFilter] = useState('all');

  // Fetch all products from the API (only once on initial load)
  const fetchProducts = async () => {
    setLoading(true);
    setError(null);
  
    // Retrieve token from localStorage
    const token = localStorage.getItem('token');
  
    const myHeaders = new Headers();
    myHeaders.append('Authorization', `Bearer ${token}`);
  
    const formData = new FormData();
    formData.append('search', '');  // No search query to get all products
    formData.append('limit', 1000); // Set a large limit to get all products
    formData.append('offset', 0);   // Start from the first product
  
    const requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: formData,
    };
  
    try {
      // Reuse the original get_product endpoint
      const response = await fetch('https://ace.dotcombusiness.in/api/admin/get_product', requestOptions);
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
  
      const result = await response.json();
      setAllProducts(result.data);  // Store all products
      setFilteredProducts(result.data);  // Initially, all products are shown
      setTotalPages(Math.ceil(result.data.length / limit));  // Calculate total pages based on all products
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };
  

  useEffect(() => {
    fetchProducts(); // Fetch all products on initial load
  }, []);

  // Handle search input
  const handleSearch = (e) => {
    const query = e.target.value.toLowerCase();
    setSearchQuery(query);

    // Filter the products based on search query
    const filtered = allProducts.filter(
      (product) =>
        product.product_name.toLowerCase().includes(query) ||
        product.SKU.toLowerCase().includes(query) ||
        product.product_code.toLowerCase().includes(query)
    );
    setFilteredProducts(filtered);
    setTotalPages(Math.ceil(filtered.length / limit)); // Adjust pagination based on filtered results
    setPage(1); // Reset to the first page
  };

  // Pagination logic: calculate paginated products
  const paginatedProducts = useMemo(() => {
    const start = (page - 1) * limit;
    const end = start + limit;
    return filteredProducts.slice(start, end);
  }, [filteredProducts, page, limit]);

  // Sync new products (manually trigger API call to add more products)
  const syncProducts = async () => {
    const token = localStorage.getItem('token');

    const myHeaders = new Headers();
    myHeaders.append('Authorization', `Bearer ${token}`);

    const requestOptions = {
      method: 'GET',
      headers: myHeaders,
    };

    try {
      const response = await fetch('https://ace.dotcombusiness.in/api/admin/fetch_products', requestOptions);
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const result = await response.json();
      const newProducts = result.new_data || [];
      setAllProducts((prevProducts) => [...prevProducts, ...newProducts]);
      setFilteredProducts((prevProducts) => [...prevProducts, ...newProducts]); // Update both all and filtered products
      setNewProductsCount(newProducts.length);
      setTotalPages(Math.ceil([...allProducts, ...newProducts].length / limit)); // Recalculate total pages
    } catch (error) {
      setError(error.message);
    }
  };

  const columns = useMemo(
    () => [
      { Header: 'SKU', accessor: 'SKU' },
      { Header: 'Code', accessor: 'product_code' },
      { Header: 'Name', accessor: 'product_name' },
      { Header: 'Category', accessor: 'category' },
      { Header: 'Sub-Category', accessor: 'sub_category' },
      {
        Header: 'Image',
        accessor: 'product_image',
        Cell: ({ value }) => (
          <img src={`https://ace.dotcombusiness.in${value}`} alt="Product" className="product-image" />
        ),
      },
      { Header: 'Basic Price', accessor: 'basic' },
      { Header: 'GST', accessor: 'gst' },
    ],
    []
  );

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable(
    {
      columns,
      data: paginatedProducts, // Use paginated products for display
      initialState: { sortBy: [] },
    },
    useSortBy
  );

  const handlePageChange = (newPage) => {
    if (newPage > 0 && newPage <= totalPages) setPage(newPage);
  };

  const handleRowSelection = (sku, isChecked) => {
    setSelectedRows((prevSelected) =>
      isChecked ? [...prevSelected, sku] : prevSelected.filter((selectedSKU) => selectedSKU !== sku)
    );
  };

  const handleImageFilterChange = (e) => {
    const filterValue = e.target.value;
    setImageFilter(filterValue);

    let filtered;
    if (filterValue === 'haveImage') {
      filtered = allProducts.filter((product) => product.product_image);
    } else if (filterValue === 'noImage') {
      filtered = allProducts.filter((product) => !product.product_image);
    } else {
      filtered = allProducts;
    }

    setFilteredProducts(filtered);
    setTotalPages(Math.ceil(filtered.length / limit)); // Recalculate total pages based on filter
    setPage(1); // Reset to first page after filtering
  };

  const handleSelectAllRows = (isChecked) => {
    const allSKUs = paginatedProducts.map((product) => product.SKU);
    setSelectedRows(isChecked ? allSKUs : []);
  };

  // Pagination controls
  const startEntry = (page - 1) * limit + 1;
  const endEntry = Math.min(page * limit, filteredProducts.length);

  // Show only 3 consecutive pages
  const getPageNumbers = () => {
    const pages = [];
    const startPage = Math.max(1, page - 1);
    const endPage = Math.min(totalPages, page + 1);

    for (let i = startPage; i <= endPage; i++) {
      pages.push(i);
    }
    return pages;
  };

  return (
    <Layout>
      <div className="search-sync-container">
        {/* Filter Dropdown */}
        <div className="filter-container">
          <select value={imageFilter} onChange={handleImageFilterChange} className="filter-dropdown">
            <option value="all">All Products</option>
            <option value="haveImage">Have Image</option>
            <option value="noImage">No Image</option>
          </select>
        </div>

        {/* Sync Button */}
        <div className="sync-container">
          <button onClick={syncProducts} className="sync-button">
            <FontAwesomeIcon icon={faSyncAlt} className="sync-icon" />
            Sync
          </button>
          {newProductsCount > 0 && (
            <span className="new-products-info" style={{ marginLeft: '15px', color: '#28a745', fontWeight: 'bold' }}>
              {newProductsCount} new products added
            </span>
          )}
        </div>

        {/* Search input */}
        <div className="search-container">
          <input
            type="text"
            placeholder="Search by product name..."
            value={searchQuery}
            onChange={handleSearch}
            className="search-input"
            autoComplete="off"
          />
        </div>
      </div>

      {loading && <p>Loading...</p>}
      {error && <p className="error">{error}</p>}

      {!loading && !error && (
        <div className="table-container">
          <table {...getTableProps()} className="products-table">
            <thead>
              {headerGroups.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()} className="header-row">
                  <th>
                    <input type="checkbox" onChange={(e) => handleSelectAllRows(e.target.checked)} />
                  </th>
                  {headerGroup.headers.map((column) => (
                    <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                      <span style={{ display: 'flex', justifyContent: 'space-between' }}>
                        {column.render('Header')}
                        <span className="sort-icon">
                          {column.isSorted ? (
                            column.isSortedDesc ? <FontAwesomeIcon icon={faArrowUp} flip="vertical" /> : <FontAwesomeIcon icon={faArrowUp} />
                          ) : (
                            <FontAwesomeIcon icon={faUpDown} />
                          )}
                        </span>
                      </span>
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {rows.map((row) => {
                prepareRow(row);
                return (
                  <tr {...row.getRowProps()} className="body-row">
                    <td>
                      <input
                        type="checkbox"
                        checked={selectedRows.includes(row.original.SKU)}
                        onChange={(e) => handleRowSelection(row.original.SKU, e.target.checked)}
                      />
                    </td>
                    {row.cells.map((cell) => (
                      <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                    ))}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      )}

      <div className="v">
        <div className="entries-info">
          Showing {startEntry} to {endEntry} of {filteredProducts.length} entries
        </div>

        <div className="pagination-controls">
          <button onClick={() => handlePageChange(page - 1)} disabled={page === 1} className="pagination-button">
            Previous
          </button>

          {getPageNumbers().map((pageNum) => (
            <button key={pageNum} onClick={() => handlePageChange(pageNum)} className={`pagination-button ${page === pageNum ? 'active' : ''}`}>
              {pageNum}
            </button>
          ))}

          <button onClick={() => handlePageChange(page + 1)} disabled={page === totalPages} className="pagination-button">
            Next
          </button>
        </div>
      </div>
    </Layout>
  );
};

export default Products;
