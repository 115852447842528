import './App.css';
import Login from './pages/login/Login';
import Dashboard from './pages/dashboard/Dashboard';
import Products from './pages/products/Products';
import Orders from './pages/orders/Orders';
import Clients from './pages/clients/Clients';
import PrivateRoute from './PrivateRoute';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import GenarateInvoice from './pages/orders/GenarateInvoice'; // Import the form component

const App = () => {
  return (
    <BrowserRouter>
      {/* Toast notifications container */}
      <ToastContainer />

      {/* Define routes */}
      <Routes>
        {/* Public route for login */}
        <Route path="/" element={<Login />} />

        {/* Private routes */}
        <Route 
          path="/dashboard" 
          element={
            <PrivateRoute>
              <Dashboard />
            </PrivateRoute>
          } 
        />
        
        <Route 
          path="/products" 
          element={
            <PrivateRoute>
              <Products />
            </PrivateRoute>
          } 
        />

        <Route 
          path="/orders" 
          element={
            <PrivateRoute>
              <Orders />
            </PrivateRoute>
          } 
        />

        <Route 
          path="/clients" 
          element={
            <PrivateRoute>
              <Clients />
            </PrivateRoute>
          } 
        />

        {/* Add the route for generate invoice */}
        <Route 
          path="/orders/genarate_invoice/:id" 
          element={
            <PrivateRoute>
              <GenarateInvoice />
            </PrivateRoute>
          } 
        />

      </Routes>
    </BrowserRouter>
  );
};

export default App;
