import './footer.css';

const Footer = ({ sidebarCollapsed }) => {
    return (
        <div className={`footer ${sidebarCollapsed ? 'collapsed' : ''}`}>
            <p className="footer-text">
                Ace Commercial Equipments © 2024, All rights reserved. Proudly Powered By Dot Com Solutions
            </p>
        </div>
    );
};

export default Footer;
