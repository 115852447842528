import './header.css';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSignOutAlt } from '@fortawesome/free-solid-svg-icons';

const Header = ({ sidebarCollapsed, className }) => {
    const navigate = useNavigate();

    const handleLogout = () => {
        console.log("Logout triggered");
        localStorage.removeItem('token');
        navigate('/', { state: { logoutMessage: 'You have successfully logged out.' } });
    };

    return (
        <div className={`header ${className} ${sidebarCollapsed ? 'collapsed' : 'expanded'}`}>
            <h1 className="header-title">Ace Care</h1>
            <button className="header-button" onClick={handleLogout} style={{ background:'#aaa'}}>
                <FontAwesomeIcon icon={faSignOutAlt} />
            </button>
        </div>
    );
};

export default Header;
