import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import './login.css';
import { useLocation } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css'; 
import logoImage from '../asset/ss.png';

const Login = () => {
    const [password, setPassword] = useState('');
    const [mobile, setMobileNumber] = useState('');
    const [otp, setOtp] = useState(new Array(6).fill("")); 
    const [isOtpRequested, setIsOtpRequested] = useState(false); 
    const [isOtpLogin, setIsOtpLogin] = useState(true); 
    const [errorMessage, setErrorMessage] = useState('');
    const navigate = useNavigate();
    const location = useLocation();

    const inputRefs = useRef([]); // References for OTP input fields

    useEffect(() => {
        if (location.state?.logoutMessage) {
            toast.success(location.state.logoutMessage);
            navigate(location.pathname, { replace: true });
        }
        if (location.state?.redirectMessage) {
            toast.error(location.state.redirectMessage);
            navigate(location.pathname, { replace: true });
        }
    }, [location.state, navigate, location.pathname]);

    // Function to reset the form fields (mobile and password)
    const resetFields = () => {
        setMobileNumber('+91');  // Clear the mobile number field
        setPassword('');      // Clear the password field
        setOtp(new Array(6).fill(""));  // Clear OTP fields if needed
    };

    // Function to clean and validate the mobile number
    const cleanMobileNumber = (mobile) => {
        const cleanedMobile = mobile.replace(/[-\s]/g, "");
        if (cleanedMobile.startsWith("91") && cleanedMobile.length === 12) {
            return `+${cleanedMobile}`; 
        }
        return null; 
    };

    // Function to handle OTP request
    const handleOtpRequest = async () => {
        const formattedMobile = cleanMobileNumber(mobile);
        if (!formattedMobile) {
            setErrorMessage(`Please enter a valid 10-digit mobile number: ${mobile}`);
            setMobileNumber('+91');
            setTimeout(() => {
                setErrorMessage('');
            }, 15000);
            return;
        }

        const requestBody = { mobile: formattedMobile };
        try {
            const response = await fetch('https://ace.dotcombusiness.in/api/get_otp', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(requestBody),
            });

            const data = await response.json();
            if (response.ok && data.data === 1) { 
                setIsOtpRequested(true); 
                toast.success(data.message || "OTP sent to your mobile.");
            } else if (response.status === 401 || 404) {
                setErrorMessage(`The number ${formattedMobile} is not registered`);
                setMobileNumber('+91');
                setTimeout(() => {
                    setErrorMessage('');
                }, 5000);
            } else {
                setMobileNumber('+91');
                setErrorMessage(`An error occurred with number ${mobile}. Please try again later.`);
                setTimeout(() => {
                    setErrorMessage('');
                }, 5000);
            }
        } catch (error) {
            setMobileNumber('+91');
            setErrorMessage(`An error occurred with number ${mobile}. Please try again later.`);
            setTimeout(() => {
                setErrorMessage('');
            }, 5000);
        }
    };

    // Function to handle OTP input change
    const handleOtpChange = (e, index) => {
        const { value } = e.target;

        if (/^[0-9]$/.test(value)) {  // Only allow digits
            const newOtp = [...otp];
            newOtp[index] = value;
            setOtp(newOtp);

            // Move to the next input if it's not the last one
            if (value !== "" && index < otp.length - 1) {
                inputRefs.current[index + 1].focus();
            }
        }
    };

    // Function to handle backspace in OTP input
    const handleBackspace = (e, index) => {
        if (e.key === "Backspace") {
            const newOtp = [...otp];
            newOtp[index] = ""; // Clear the current input value
            setOtp(newOtp);

            // Move focus to the previous input field if available
            if (index > 0) {
                inputRefs.current[index - 1].focus();
            }
        }
    };

    // Handle pasting a full OTP (6 digits)
    const handleOtpPaste = (e) => {
        const pasteData = e.clipboardData.getData('text').slice(0, 6);  // Only take the first 6 characters
        const newOtp = [...otp];

        pasteData.split('').forEach((char, index) => {
            if (/^[0-9]$/.test(char) && index < 6) {
                newOtp[index] = char;
            }
        });

        setOtp(newOtp);

        // Automatically focus the last filled input
        inputRefs.current[Math.min(pasteData.length, 5)].focus();
    };

    // Function to handle OTP login
    const handleOtpLogin = async () => {
        const otpCode = otp.join(""); 
        const formattedMobile = cleanMobileNumber(mobile);

        if (!formattedMobile) {
            setErrorMessage('Please enter a valid mobile number.');
            return;
        }

        try {
            const requestBody = { mobile: formattedMobile }; 
            const response = await fetch(`https://ace.dotcombusiness.in/api/login/${otpCode}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(requestBody),
            });

            const data = await response.json();

            if (response.ok && data.data && data.data.token) {
                localStorage.setItem('token', data.data.token);
                toast.success('Login successful!');
                if (data.data.role === 'admin') {
                    navigate('/dashboard', { state: { loginMessage: 'Welcome back, admin!' } });
                } else {
                    alert('Fine, you are a user only.');
                    resetFields();  // Reset form fields
                }
            } else {
                setErrorMessage(data.message || 'OTP verification failed. Please try again.');
            }
        } catch (error) {
            resetFields();
            setErrorMessage(`An error occurred with number ${mobile}. Please try again later.`);
        }
    };

    // Function to handle password login
    const handlePasswordLogin = async () => {
        const formattedMobile = cleanMobileNumber(mobile);

        if (!formattedMobile) {
            setErrorMessage('Please enter a valid mobile number.');
            resetFields();
            return;
        }
        if (!password) {
            setErrorMessage('Password is not Given.');
            resetFields();
            setTimeout(() => {
                setErrorMessage('');
            }, 5000);
            return;
        }

        const requestBody = {
            password: password,
            mobile: formattedMobile 
        };

        try {
            const response = await fetch('https://ace.dotcombusiness.in/api/login', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(requestBody),
            });

            const data = await response.json();

            if (response.ok && data.data && data.data.token) {
                localStorage.setItem('token', data.data.token);
                toast.success('Login successful!');
                if (data.data.role === 'admin') {
                    navigate('/dashboard', { state: { loginMessage: 'Welcome back, admin!' } });
                } else {
                    alert('Fine, you are a user only.');
                    resetFields();
                }
            } else {
                setErrorMessage(`Invalid Mobile Number or Password.`);
                resetFields();
                setTimeout(() => {
                    setErrorMessage('');
                }, 5000);  
            }
        } catch (error) {
            setErrorMessage('Password is not Given.');
            setTimeout(() => {
                setErrorMessage('');
            }, 5000);
        }
    };

    return (
        <>
            <div className="loginPage">
                <div className="imgg">
                    <img
                        src={logoImage}  
                        alt="Brand Logo"
                        className="rounded-full object-cover "
                        style={{ width: '20vw' }}  
                    />
                </div>
                <h1 className="heading-loginPage" style={{ color: '#e31e25' }}>Ace Care</h1>
                <div className="phone-input-container">
                    <PhoneInput
                        country={'in'}
                        value={mobile}
                        onChange={(value) => {
                            const cleanedValue = value.replace(/\D/g, ""); 
                            setMobileNumber(cleanedValue); 
                        }}
                        disabled={isOtpRequested && isOtpLogin}
                        inputClass="input-loginPage"
                    />
                </div>

                {isOtpLogin ? (
                    !isOtpRequested ? (
                        <button className="button-loginPage" onClick={handleOtpRequest} style={{ background: '#e31e25' }}>Get OTP</button>
                    ) : (
                        <>
                            <div className="otp-input-container" onPaste={handleOtpPaste}>
                                {otp.map((data, index) => (
                                    <input
                                        key={index}
                                        type="text"
                                        className="otp-input"
                                        maxLength="1"
                                        value={data}
                                        onChange={e => handleOtpChange(e, index)}
                                        onKeyDown={e => handleBackspace(e, index)}
                                        ref={el => (inputRefs.current[index] = el)} 
                                    />
                                ))}
                            </div>
                            <button className="button-loginPage" onClick={handleOtpLogin} style={{ background: '#e31e25' }}>Login with OTP</button>
                        </>
                    )
                ) : (
                    <>
                        <input className="input-loginPage"
                            value={password}
                            placeholder="Password"
                            type="password"
                            onChange={e => setPassword(e.target.value)}
                        />
                        <button className="button-loginPage" onClick={handlePasswordLogin} style={{ background: '#e31e25' }}>Login</button> 
                    </>
                )}

                <p className="toggle-login-method">
                    {isOtpLogin ? (
                        <span onClick={() => setIsOtpLogin(false)} style={{ cursor: 'pointer', color: '#007bff' }}>Login with password</span>
                    ) : (
                        <span onClick={() => setIsOtpLogin(true)} style={{ cursor: 'pointer', color: '#007bff' }}>Login with OTP</span>
                    )}
                </p>

                {errorMessage && 
                    <p className="error">
                        <strong>{errorMessage}</strong>
                    </p>
                }
                <ToastContainer />
            </div>
            <div className="foot" style={{ height:'8vh', display:'flex', alignItems:'center', justifyContent:'center', flexDirection:'column' }}>
                <p className="footer-text" style={{ color: 'red'}}>
                    Ace Commercial Equipments © 2024, All rights reserved. Proudly Powered By <span>Dot Com Solutions</span>
                </p>
            </div>
        </>
    );

};

export default Login;
