import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import SideBar from '../sideBar/SideBar';
import Header from '../header/Header';
import Footer from '../footer/Footer'; // Adjust the path as necessary
import './layout.css'; // Import the new CSS file for styles

const Layout = ({ children }) => {
    const [sidebarCollapsed, setSidebarCollapsed] = useState(true);
    const location = useLocation();

    const handleSidebarCollapseChange = (collapsed) => {
        setSidebarCollapsed(collapsed);
    };
    const headerClass = sidebarCollapsed ? 'header-collapsed' : 'header-expanded';

    return (
        <div className="layout-container">
            <SideBar onCollapseChange={handleSidebarCollapseChange} />
            <div className={`content-container ${sidebarCollapsed ? 'collapsed' : 'expanded'} ${location.pathname === '/products' ? 'products-page' : ''}`}>
                <Header sidebarCollapsed={sidebarCollapsed} className={headerClass} />
                <main className="main-content" >
                    {children}
                </main>
                <Footer sidebarCollapsed={sidebarCollapsed} />
            </div>
        </div>
    );
};

export default Layout;
